<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-panel>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("profile.edit") }}
            </div>

            <div class="subtitle-1 font-weight-light">
              {{ $t("profile.complete") }}
            </div>
          </template>

          <v-form @submit.prevent="updateProfile" ref="form">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('profile.username')"
                    v-model="form.name"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('profile.company')"
                    v-model="form.company"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('profile.job_title')"
                    v-model="form.job_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.first_name"
                    :rules="rules.first_name"
                    :label="$t('profile.first_name')"
                    @input="resetInputError('first_name')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.last_name"
                    :rules="rules.last_name"
                    :label="$t('profile.last_name')"
                    @input="resetInputError('last_name')"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    class="purple-input"
                    v-model="form.address"
                    :label="$t('profile.address')"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('profile.email')"
                    class="purple-input"
                    v-model="form.email"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('profile.city')"
                    class="purple-input"
                    v-model="form.city"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('profile.country')"
                    class="purple-input"
                    v-model="form.country"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.postcode"
                    class="purple-input"
                    :label="$t('profile.postcode')"
                    type="number"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="form.about_me"
                    class="purple-input"
                    :rules="[
                      (v) =>
                        (v || '').length <= 255 ||
                        'Description must be 255 characters or less',
                    ]"
                    :label="$t('profile.about_me')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
                    $t(errorMessage)
                  }}</v-alert>
                  <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
                    $t("profile.User saved")
                  }}</v-alert>

                  <update-password></update-password>
                  <v-btn
                    color="primary"
                    class="mr-0 float-right mt-3"
                    type="submit"
                  >
                    {{ $t("profile.update") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crudForm from "mixins/crudForm";
import UpdatePassword from "@/pages/admins/UserPasswordUpdate";
export default {
  components: {
    UpdatePassword,
  },
  mixins: [crudForm],
  created() {
    this.loadProfile();
  },
  data() {
    return {
      apiQuery: "/admins/",
      file: "",
      disabled: 1,
      changed: false,
      error: false,
      image_url: "/img/avatardefault.png",
      pwd_form: {},
      rules: {
        first_name: [
          () =>
            this.getInputError("first_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.first_name"),
            }),
        ],
        last_name: [
          () =>
            this.getInputError("last_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.last_name"),
            }),
        ],
      },
    };
  },
  methods: {
    loadProfile(uri = "/profile") {
      this.get(uri).then((data) => {
        this.form = data;
        this.image_url = data.image_url
          ? "/storage/" + data.image_url
          : "/img/avatardefault.png";
      });
    },
    changeImage(data) {
      this.image_url = data;
    },
    updateProfile() {
      this.errorMessage = null;
      this.serverErrors = null;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.patch("/update", this.form)
        .then((res) => {
          this.savedItem = res;
          setTimeout(() => {
            this.savedItem = null;
          }, 2500);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
  },
};
</script>
